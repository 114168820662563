(function($) {

    $(document).ready(function() {

      // Language switcher links.
      $('.language-switcher').click(function(e) {
        e.preventDefault();
        var $link = $(e.target);
        var lang = $link.data('lang');
        var $body = $('body');
        var currentLang = $body.data('lang');

        if (lang == currentLang) return;

        // Change lang.
        $body.attr('lang', lang);
        // Change links.
        $body.find('a.i18n-link').each(function() {
          $(this).attr('href', $(this).data('link' + lang));
        });
        // Change page title.
        var $title = $('head title');
        $title.html($title.data(lang));
      });

      // Set initial language.
      var userLang = navigator.language || navigator.userLanguage;
      if (userLang.indexOf('es') === 0) {
        $('.language-switcher a[data-lang=es]').click();
      }

    });

  })(jQuery);
